@use 'media';
@use 'reusables';

header {
  height: 100px;
  width: 100%;
  position: relative;
  z-index: 1000;

  .container {
    border: none;
  }

  .top-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px;

    nav {
      display: inline-flex;
      align-items: center;

      div {
        margin-right: 1rem;

        i {
          font-size: 1rem;
          margin: 0 0.3rem;
        }
      }
    }
  }
}

#atf {
  height: 100%;
  position: relative;

  .container {
    border: none;
  }

  h1 {
    margin: 100px 0 0 0;
    padding: 0;
    font-size: 52px;
    font-weight: bold;
    color: $black;

    @include tablet {
      font-size: 34px;
      margin: 50px 0 0 0;
    }
  }

  p {
    margin: 20px 0 30px 0;
    padding: 0;
    font-size: 26px;
    width: 60%;
    color: rgba($black, 0.8);

    @include tablet {
      width: 100%;
      font-size: 20px;
    }
  }

  a {
    display: inline-block;
    margin-right: 10px;
    width: 170px;
    padding: 12px 0;
    background: $white;
    color: $black;
    text-decoration: none;
    text-align: center;
    font-size: 22px;
    margin-bottom: 20px;

    @include mobile {
      width: 48%;
      margin-right: 2%;
      font-size: 18px;
    }

    i {
      margin-right: 8px;
    }

    &:first-child {
      background: $black;
      color: $white;
    }
  }
}

#expertise {
  margin-top: 80px;

  .iconbox {
    height: 120px;
    width: 120px;
    display: inline-block;
    position: relative;
    margin-right: 20px;
    text-align: center;

    @include mobile {
      width: 45%;
      height: 150px;
      margin-right: 5%;
    }

    .iconbox-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      i {
        font-size: 52px;
        margin: 0;
        padding: 0;
      }

      h3 {
        margin: 10px 0 0 0;
        padding: 0;
        font-weight: 400;
      }
    }
  }
}

#connect {
  margin-top: 80px;

  .connection-links {
    margin-top: 30px;
    a {
      display: inline-block;
      width: 130px;
      text-align: center;
      padding: 12px 0;
      color: $black;
      box-sizing: border-box;
      margin-right: 20px;
      border: 1px solid transparent;

      @include mobile {
        width: 48%;
        margin-right: 2%;
        font-size: 18px;
        margin-bottom: 20px;
      }

      &:first-child {
        background: $black;
        color: $white;

        &:hover {
          border-color: $black;
          color: $black;
          background: $white;
        }
      }

      &:hover {
        background: $black;
        color: $white;
      }

      i {
        margin-right: 8px;
      }
    }
  }
}

#about {
  margin-top: 80px;

  h1 {
    font-size: 30px;
  }

  img {
    margin-top: 30px;
    height: 260px;
    width: auto;

    @include mobile {
      height: 190px;
    }
  }

  p {
    width: 60%;
    font-size: 18px;

    @include tablet {
      width: 100%;
    }
  }

  a {
    display: block;
    width: 150px;
    padding: 12px 0;
    background: $black;
    color: $white;
    text-align: center;

    @include mobile {
      width: 100%;
    }
  }
}

#projects {
  margin-top: 80px;

  .project-container {
    display: block;
    vertical-align: top;
    margin-right: 10%;
    margin-bottom: 8%;

    h3 {
      font-size: 30px;
      margin: 0;
      padding: 0;
    }

    p {
      font-size: 18px;
    }

    a {
      display: block;
      width: 230px;
      margin-top: 20px;
      text-align: center;
      padding: 12px 0;
      box-sizing: border-box;
      border: 1px solid $black;
      color: $black;
      background: $white;

      @include mobile {
        width: 100%;
      }

      &:hover {
        background: $black;
        color: white;
      }
    }
  }
}

#contact {
  margin: 30px 0 0 0;

  .contactBlock {
    width: 60%;

    @include tablet {
      width: 100%;
    }

    h3 {
      font-size: 30px;
      margin: 0;
      padding: 0;
    }
    p {
      font-size: 18px;

      @include tablet {
        width: 100%;
      }
    }

    a {
      color: inherit;
      font-style: italic;
    }
  }
}

footer {
  .container {
    border: none !important;
  }
  .footer-container {
    margin: 30px auto 0 auto;
    border-top: 1px solid rgba($black, 0.2);
    text-align: center;

    @include tablet {
      width: 80%;
    }

    img {
      margin-top: 25px;
    }

    .icons-container {
      margin: 20px 0;

      .iconlink {
        display: inline-block;
        padding: 15px;
        text-align: center;
        color: rgba($black, 0.7);
        font-size: 18px;

        &:first-child {
          margin-left: 0;
        }

        &:hover {
          color: $black;
        }
      }
    }

    .copyright {
      margin-top: 10px;
      font-size: 14px;
      color: rgba($black, 0.7);
    }
  }
}
