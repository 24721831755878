/*************************************************
 MEDIA QUERY DEFINITIONS
*************************************************/

$mobile-width: 450px;
$tablet-width: 768px;
$large-tablet-width: 1024px;
$laptop-width: 1200px;
$desktop-width: 1920px;

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin large-tablet {
  @media (max-width: #{$large-tablet-width}) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: #{$laptop-width}) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}
