@use 'media';
@use 'reusables';

#casestudy_head {
  height: 100%;
  position: relative;

  h1 {
    margin: 100px 0 0 0;
    padding: 0;
    font-size: 38px;
    font-weight: bold;
    color: $black;

    @include tablet {
      font-size: 34px;
      margin: 50px 0 0 0;
    }
  }

  p {
    margin: 20px 0 50px 0;
    padding: 0;
    font-size: 22px;
    width: 60%;
    color: rgba($black, 0.8);

    @include tablet {
      width: 100%;
      font-size: 20px;
    }
  }

  a {
    margin-right: 10px;
    padding: 12px 20px;
    border: 2px solid $black;
    background: $white;
    color: $black;
    text-decoration: none;
    text-align: center;
    font-size: 22px;
    margin-bottom: 20px;

    @include mobile {
      display: block;
      width: 100%;
      padding: 12px 0;
      margin-right: 0;
    }

    @include mobile {
      margin-right: 2%;
      font-size: 18px;
    }

    i {
      margin-left: 8px;
    }
  }
}

#cards {
  margin-top: 80px;
  position: relative;
  width: 100%;
  height: 500px;

  @include tablet {
    height: 1450px;
  }

  .pattern-container {
    opacity: 0.8;
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .card_container {
    position: absolute;
    top: 80px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;

    @include tablet {
      display: block;
      top: 40px;
    }

    .card {
      background: $white;
      width: 30%;
      margin: 0;
      padding: 20px 20px 40px 20px;
      box-shadow: 0px 0px 10px 5px rgba($black, 0.1);

      @include tablet {
        width: 100%;
        margin-bottom: 30px;
      }

      .steps {
        padding-bottom: 20px;
        font-size: 24px;
        border-bottom: 1px solid rgba($black, 0.2);
      }

      .cardTitle {
        font-size: 24px;
        color: $black;
      }

      .card_content {
        font-size: 16px;
        line-height: 140%;
        color: rgba($black, 0.8);
        margin-bottom: 30px;
      }

      a {
        display: block;
        width: 140px;
        padding: 12px 0;
        border: 2px solid $black;
        color: $black;
        background: $white;
        text-align: center;

        i {
          font-weight: bold;
          margin-left: 6px;
        }
      }

      &:hover {
        box-shadow: 0px 0px 10px 5px rgba($black, 0.1);
      }
    }
  }
}

#details {
  margin-top: 80px;

  h1 {
    font-size: 38px;
    margin-bottom: 50px;
  }
  .imagery_container {
    height: 550px;
    width: 50%;
    margin-right: 3%;
    padding: 0;
    position: relative;

    @include tablet {
      height: 370px;
      margin-right: 0;
      margin-bottom: 30px;
    }

    .the_image {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: auto;

      @include tablet {
        width: 100%;
        height: auto;
      }
    }
  }
  .details_container {
    width: 45%;
    height: 550px;
    margin: 0;
    padding: 0;

    @include tablet {
      height: 100%;
      margin-bottom: 30px;
    }

    h2 {
      font-size: 24px;
      color: $black;
      margin: 0 8px 0 0;
    }

    p {
      font-size: 18px;
      color: rgba($black, 0.8);
      margin-bottom: 22px;
    }
  }

  .imagery_container,
  .details_container {
    display: inline-block;
    vertical-align: top;

    @include tablet {
      display: block;
      width: 100%;
    }
  }
}

#cta {
  height: 80px;
  background: $black;

  a {
    display: block;
    padding: 12px;
    width: 200px;
    background: $white;
    color: $black;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include mobile {
      width: 100%;
    }

    i {
      margin-left: 6px;
    }
  }
}

#case_study-projects {
  padding-top: 60px;
  padding-bottom: 30px;
  background: #f2f2f2;

  @include mobile {
    padding-top: 30px;
  }

  h1 {
    font-size: 16px;
  }
  .project-card {
    h2 {
      font-size: 30px;
      color: $black;
    }

    p {
      width: 80%;
      font-size: 18px;
      color: rgba($black, 0.8);

      @include tablet {
        width: 100%;
      }
    }
  }
}
